import { IconLookup } from '@fortawesome/pro-solid-svg-icons';

// TODO: at some point it might be better to move these variables
// to env. files;
export const FONT_AWESOME_API_URL = 'https://api.fontawesome.com';
export const FONT_AWESOME_AUTHORIZATION_TOKEN =
  '5B8118E3-B3EC-4BAB-9B0E-E01347FD1E7C';
export const FONT_AWESOME_GRAPHQL_QUERY = `
  query icons {
    me {
      kit(token: "3eed72c166") {
        iconUploads {
          iconDefinition
        }
      }
    }
  }
`;

export const CUSTOM_ICON_LOOKUPS: IconLookup[] = [
  {
    iconName: 'agrirouter',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'g-dealer',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'g-fields',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'g-search-checks',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'g-spare',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'g-used',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'grimme-g',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'i-systems',
    prefix: 'fak',
  } as unknown as IconLookup,
  {
    iconName: 'machine-grimme',
    prefix: 'fak',
  } as unknown as IconLookup,
];
