export type CityLocation = {
  city: string;
  country: string;
  countryCode: string;
  formattedAddress: string;
  postalCode: string;
};

const convertPlaceResultToCityLocation = (
  result: google.maps.places.PlaceResult,
): Partial<CityLocation> => {
  const {
    address_components: addressComponents,
    formatted_address: formattedAddress,
  } = result;
  const response: Partial<CityLocation> = {
    formattedAddress,
  };

  addressComponents?.forEach((addressComponent) => {
    const {
      long_name: longName,
      short_name: shortName,
      types,
    } = addressComponent;

    if (types.includes('postal_code') && types.length === 1) {
      response.postalCode = longName;
    } else if (types.includes('country')) {
      response.country = longName;
      response.countryCode = shortName;
    } else if (types.includes('political') && !types.includes('sublocality')) {
      if (types.includes('locality')) {
        response.city = longName;
      } else if (!response.city) {
        response.city = longName;
      }
    }
  });

  return response;
};

export const useLocationDetails = () => {
  const getPlaceDetails = (
    placeId: string,
  ): Promise<google.maps.places.PlaceResult | null> => {
    return new Promise((resolve) => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );

      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          return resolve(place);
        }

        resolve(null);
      });
    });
  };

  const getFormattedPlaceDetails = async (
    placeId: string,
  ): Promise<Partial<CityLocation>> => {
    const placeDetails = await getPlaceDetails(placeId);

    return convertPlaceResultToCityLocation(placeDetails || {});
  };

  return { getFormattedPlaceDetails, getPlaceDetails };
};
