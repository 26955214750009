'use client';

import { fetcher } from '@/lib/SWR/fetchers/fetcher';
import {
  ComponentsConfigProvider,
  GrimmeComponentsProvider,
  LocalizationProvider,
} from '@grimme/components';
import theme from '@grimme/theme';
import { TooltipProvider } from '@grimme/ui-components/tooltip';
import { CssBaseline, ThemeProvider, Typography } from '@mui/material';
import { createInstance, Resource } from 'i18next';
import React, { FC, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { SWRConfig } from 'swr';
import { UserCentrics } from '~/components';
import store from '~/redux';
import { FontAwesomeService } from '~/utils/font-awesome-icons';
import { ShowContactProvider } from '~/utils/useShowContact';
import { initTranslations } from './i18n';
import AnalyticsProvider from './providers/analytics-provider';
import { AuthProvider } from './providers/auth-provider';
import GoogleMapsProvider from './providers/google-maps-provider';
import { SentryProvider } from './providers/sentry-provider';

FontAwesomeService.includeAllIcons();

// TODO: get rid of ProviderWrapper, remove unnecessary providers
export const AppProviders: FC<
  PropsWithChildren<{ i18nConfigs?: { resources: Resource }; locale: string }>
> = ({ children, i18nConfigs, locale }) => {
  const i18n = createInstance();
  initTranslations(locale, i18n, i18nConfigs?.resources);

  return (
    <>
      <UserCentrics />
      <I18nextProvider i18n={i18n}>
        <React.StrictMode>
          <ComponentsConfigProvider
            t={i18n.t as (key: string, defaultValue?: string) => string}
            language={i18n.language}
          >
            <TooltipProvider>
              <ReduxProvider store={store}>
                <AnalyticsProvider>
                  <SWRConfig
                    value={{
                      errorRetryCount: 3,
                      errorRetryInterval: 15000,
                      fetcher,
                      keepPreviousData: true,
                    }}
                  >
                    <AuthProvider>
                      <SentryProvider>
                        <ThemeProvider theme={theme}>
                          <CssBaseline />
                          <Typography component="div">
                            <GrimmeComponentsProvider>
                              <LocalizationProvider>
                                <ShowContactProvider>
                                  {children}
                                </ShowContactProvider>
                              </LocalizationProvider>
                            </GrimmeComponentsProvider>
                          </Typography>
                        </ThemeProvider>
                      </SentryProvider>
                    </AuthProvider>
                  </SWRConfig>
                </AnalyticsProvider>
              </ReduxProvider>
            </TooltipProvider>
          </ComponentsConfigProvider>
        </React.StrictMode>
      </I18nextProvider>
      <GoogleMapsProvider />
    </>
  );
};
