import Script from 'next/script';
import React from 'react';
import { environment } from '~/environments';

export default function GoogleMapsProvider() {
  return (
    <Script
      async
      defer
      src={`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`}
    />
  );
}
