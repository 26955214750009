'use client';

// TODO: change to Server Component once we can check server side if user is authenticated

import { Loader, LoaderVariants } from '@grimme/components';
import { useSession } from '@grimme/next-grimme-auth';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsUnauthorized,
  selectIsUserFetchFinished,
} from '~/redux/auth.slice';

export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const { status: sessionStatus } = useSession();
  const router = useRouter();
  const params = useParams<{ locale: string }>();
  const path = usePathname();
  const isUserFetchFinished = useSelector(selectIsUserFetchFinished);
  const isUnauthorized = useSelector(selectIsUnauthorized);
  const isLoadingSession = sessionStatus === 'loading';
  const isOnPublicPage = path === `/${params?.locale}`;

  // when on public pages (e.g. landing page)
  if (isOnPublicPage) {
    // Redirect to home page;
    if (sessionStatus === 'authenticated') {
      const homePageHref = `/${params?.locale}/home`;
      // router.prefetch(homePageHref);
      router.push(homePageHref);

      return null;
    }

    const shouldShowLoader = isUnauthorized || isLoadingSession;

    if (shouldShowLoader) {
      return <Loader variant={LoaderVariants.plain} />;
    }
  } else {
    // Redirect to landing page;
    if (sessionStatus === 'unauthenticated') {
      router.push(`/${params?.locale}`);

      return null;
    }

    // when on private (authenticated) pages
    const shouldShowLoader =
      isLoadingSession ||
      (sessionStatus === 'authenticated' && !isUserFetchFinished);

    if (shouldShowLoader) {
      return <Loader variant={LoaderVariants.plain} />;
    }
  }

  return children;
};
