import { createInstance, i18n, Resource } from 'i18next';
import { cache } from 'react';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';
import { locales } from '~/gen';
import { environment } from '~/environments';

const i18nextBackendOptions = {
  backendOptions: [
    {
      addPath: '',

      // expiration
      expirationTime: 24 * 60 * 60 * 1000,

      loadPath: `${environment.translationApi}/translations?language={{lng}}`,
    },
  ],
  backends: [HttpBackend],
};

export async function initTranslations(
  locale: string,
  i18nInstance?: i18n,
  resources?: Resource,
) {
  i18nInstance = i18nInstance || createInstance();

  i18nInstance.use(initReactI18next);
  i18nInstance.use(Backend);

  await i18nInstance.init({
    lng: locale,
    resources,
    fallbackLng: locale,
    backend: i18nextBackendOptions,
    supportedLngs: locales,
    react: { useSuspense: false },
  });

  return i18nInstance;
}

export default cache(initTranslations);
