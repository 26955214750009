import Butter from 'buttercms';
import { environment } from '~/environments';

export const getPage = async <T extends Record<PropertyKey, unknown>>(
  locale: string,
  preview: boolean,
  pageSlug: string,
  pageType = '*',
) => {
  const butterToken = environment.cmsAuthToken || '';
  const butter = Butter(butterToken);

  const butterParams: { locale: string; preview: 0 | 1 } = {
    locale,
    preview: preview ? 1 : 0,
  };

  const page = await butter.page.retrieve<T>(pageType, pageSlug, butterParams);

  return page.data?.data;
};
