import { useEffect, useState } from 'react';
import { getPage } from '../../utils/butter/getPage';

export type Country = {
  code: string;
  name: string;
};

export const useGetCountriesList = () => {
  const [countryData, setCountryData] = useState<Country[]>([]);
  const [countryError, setCountryError] = useState<unknown>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPage<{ countries?: Country[] }>(
          'de',
          false,
          'countries-list',
          '*',
        );
        const countries = data?.fields?.countries;
        if (countries) {
          setCountryData(countries);
        }
      } catch (error: unknown) {
        console.log(error);
        setCountryError('error fetching data!');
      }
    };

    fetchData();
  }, []);

  return { countryData, countryError };
};
