import { IconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { getCachedAxiosInstance } from '../axios';
import { retryAsync } from '../utils';
import {
  CUSTOM_ICON_LOOKUPS,
  FONT_AWESOME_API_URL,
  FONT_AWESOME_AUTHORIZATION_TOKEN,
  FONT_AWESOME_GRAPHQL_QUERY,
} from './constants';
import { IconKit } from './types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { library } = require('@fortawesome/fontawesome-svg-core');

export class FontAwesomeService {
  private static axios = getCachedAxiosInstance();

  static getFontAwesomeToken = async () => {
    try {
      const response = await this.axios?.post<{ access_token: string }>(
        `${FONT_AWESOME_API_URL}/token`,
        null,
        {
          cache: false,
          headers: {
            Authorization: `Bearer ${FONT_AWESOME_AUTHORIZATION_TOKEN}`,
          },
        },
      );

      return response?.data.access_token;
    } catch (error) {
      console.error('Fetching font awesome token error:', error);

      return undefined;
    }
  };

  static getFontAwesomeIconsData = async (authToken: string) => {
    try {
      const response = await this.axios?.post<{
        data: { me: { kit: { iconUploads: IconKit[] } } };
      }>(
        FONT_AWESOME_API_URL,
        {
          query: FONT_AWESOME_GRAPHQL_QUERY,
        },
        {
          cache: false,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      return response?.data.data?.me?.kit?.iconUploads;
    } catch (error) {
      console.error('Fetching font awesome icons error: ', error);

      return undefined;
    }
  };

  /**
   * TODO: create a setup to fetch it once on the server and run library.add in a single place
   */
  static includeCustomIcons = async (...iconLookups: IconLookup[]) => {
    const iconLookupStrings = iconLookups.map(
      (i) => `${i.prefix}|${i.iconName}`,
    );

    const fontAwesomeAuthToken = await retryAsync(() =>
      this.getFontAwesomeToken(),
    );

    const fontAwesomeIconData = fontAwesomeAuthToken
      ? await retryAsync(() =>
          this.getFontAwesomeIconsData(fontAwesomeAuthToken),
        )
      : undefined;

    const standardIcons = [] as IconDefinition[];
    const customIcons = fontAwesomeIconData?.map((icon) => icon.iconDefinition);

    let allFontAwesomeIcons = customIcons
      ? standardIcons.concat(customIcons)
      : standardIcons;

    if (iconLookups.length > 0) {
      allFontAwesomeIcons = allFontAwesomeIcons.filter((i) =>
        iconLookupStrings.includes(`${i.prefix}|${i.iconName}`),
      );
    }

    library.add(...allFontAwesomeIcons);

    return allFontAwesomeIcons;
  };

  static includeStandardIcons = () => {
    library.add(fab, fas);
  };

  static includeAllIcons = async () => {
    this.includeStandardIcons();

    await this.includeCustomIcons(...CUSTOM_ICON_LOOKUPS);
  };
}
