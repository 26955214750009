import { useUserCentricsConsent } from '@grimme/gdap';
import * as Sentry from '@sentry/nextjs';
import { PropsWithChildren, useEffect } from 'react';

export function SentryProvider({ children }: PropsWithChildren<unknown>) {
  const isSentryConsentGiven = useUserCentricsConsent('Sentry');
  const isProduction =
    process.env.NEXT_PUBLIC_APP_ENV !== 'test' &&
    process.env.NODE_ENV !== 'development';

  useEffect(() => {
    Sentry.init({
      debug: false,
      dsn: 'https://fc4d4a87f9f649d0b7b79ccd7753af9c@o1318498.ingest.sentry.io/6573062',
      enabled: Boolean(isProduction && isSentryConsentGiven),
      tracesSampleRate: 0,
    });
  }, [isProduction, isSentryConsentGiven]);

  return <>{children}</>;
}
