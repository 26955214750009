import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultLocale } from '~/gen';
import { useDebounce } from '../useDebounce';

export const useLocationAutoComplete = () => {
  const [location, setLocation] = useState<string>('');
  const { i18n } = useTranslation();
  const [locationPredictions, setLocationPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const debouncedLocationValue = useDebounce(location, 1000);

  const { language } = i18n ?? { language: defaultLocale };

  useEffect(() => {
    if (!debouncedLocationValue) {
      setLocationPredictions([]);

      return;
    }

    const autocompleteOptions: google.maps.places.AutocompletionRequest = {
      input: debouncedLocationValue,
      language,
      types: ['postal_code'],
    };

    if (!window.google) return;

    const service = new window.google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      autocompleteOptions,
      (locationPredictions, status) => {
        if (status === 'OK') {
          setLocationPredictions(locationPredictions || []);
        } else {
          setLocationPredictions([]);
        }
      },
    );
  }, [debouncedLocationValue, language]);

  return {
    location,
    locationPredictions,
    setLocation,
  };
};
