import axios, { AxiosRequestConfig } from 'axios';

/**
 * Fetcher that is used by SWR
 *
 * @param url URL to fetch
 * @param config optional AxiosRequestConfig
 * @returns The data that was fetched
 */
export const fetcher = ({
  config,
  url,
}: {
  config: AxiosRequestConfig;
  url: string;
}) =>
  axios
    .get(url, config)
    .then((res) => res.data)
    .catch((error) => {
      // Handle network error
      console.error('Network error occurred:', error);
      throw error;
    });

export const listFetcher = ({
  config,
  urls,
}: {
  config: AxiosRequestConfig;
  urls: string[];
}) =>
  Promise.all(
    urls.map((url) => axios.get(url, config).then((res) => res.data)),
  ).catch((error) => {
    // Handle network error
    console.error('Network error occurred:', error);
    throw error;
  });
