import { Geolocation } from '@grimme/types';
import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_GEOLOCATION } from '../../utils/consts';
import { environment } from '../../environments';

export const fetchGeolocation = async (
  language: string,
): Promise<Geolocation> => {
  const threeDays = 1000 * 60 * 60 * 24 * 3;
  const curLocation = localStorage.getItem('geolocation');
  const lastFetchedDate = localStorage.getItem('geolocation-date');

  if (curLocation && Number(lastFetchedDate) + threeDays > Date.now()) {
    return JSON.parse(curLocation);
  }

  const location: Geolocation = (
    await axios(`${environment.apiUrl}/geolocation`, {
      params: {
        language,
      },
    })
  ).data;

  localStorage.setItem('geolocation', JSON.stringify(location));
  localStorage.setItem('geolocation-date', Date.now().toFixed(0));
  return location;
};

export const useGeolocation = (defaultLocation = DEFAULT_GEOLOCATION) => {
  const [geolocation, setGeolocation] =
    React.useState<Geolocation>(defaultLocation);

  const {
    i18n: { language },
  } = useTranslation();

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const geolocation: Geolocation = await fetchGeolocation(language);
        if (isMounted) {
          setGeolocation(geolocation);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [language]);

  return geolocation;
};
