'use client';

import {
  AnalyticsProvider as GDAPProvider,
  useUserCentricsConsent,
} from '@grimme/gdap';
import { useSession } from '@grimme/next-grimme-auth';
import React, { PropsWithChildren } from 'react';
import { environment } from '~/environments';
import { useMemoCompare } from '~/hooks/use-memo-compare/use-memo-compare';

const { appVersion, commitHash, insightsKey, projectName } = environment;

export default function AnalyticsProvider(props: PropsWithChildren) {
  const isAnalyticsConsentGiven = useUserCentricsConsent(
    'Azure Application Insights',
  );
  const { data } = useSession();

  const username = useMemoCompare(
    data?.user?.name,
    (prev, next) => prev === next,
  );

  if (!commitHash || !insightsKey || !projectName || !appVersion) {
    const notSet: string[] = [];
    const envs = { appVersion, commitHash, insightsKey, projectName };
    for (const name in envs) {
      if (!envs[name as keyof typeof envs]) {
        notSet.push(name);
      }
    }
    throw new Error(
      `The following environment variables have not been set: ${notSet.join(
        ', ',
      )}`,
    );
  }

  return (
    <GDAPProvider
      instrumentationKey={insightsKey}
      isConsentGiven={isAnalyticsConsentGiven}
      defaultEnvelopeData={{
        commit: commitHash,
        project: projectName,
        version: appVersion,
      }}
      username={username}
    >
      {props.children}
    </GDAPProvider>
  );
}
