import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Geolocation = {
  countryName: string;
  countryFlag: string;
  countryCode?: string;
  zipcode?: string;
  latitude: string;
  longitude: string;
};

export enum PictureSize {
  SMALL = 0,
  MEDIUM = 1,
  LARGE = 2,
}

export type SerialMachineData = {
  SerialNumber?: string;
  GscSerialNumber?: string | null;
  ManufacturingYear: number;
  Item?: {
    Id?: string;
    Name?: string;
    SimpleName?: string;
    ItemUsageType?: {
      Id: number;
      Description?: string;
    };
  };
  AuthorizationCode?: string;
  //TODO: Remove Preview once /machines endpoint is updated to return data from GREDA api
  Preview?: {
    Size?: PictureSize;
    Url?: string;
  }[];
};

export type MachineMapping = {
  IsTelemetryActive?: boolean;
  MachineSerialNumber: string;
  Name?: string;
  ManufacturingYear?: number;
};

//TODO: Remove it once types are fetched; also update permission types in the entire file
export enum PermissionID {
  ADMIN = 'Admin',
  TELEMETRY = 'Telemetry',
}

export interface FakePermission {
  Id: PermissionID;
  Label: string;
}

export type UserCompanyData = {
  Id: string;
  MachineMappings?: MachineMapping[];
  BusinessRelationId: string;
  Name?: string;
  FavoriteDealerBusinessRelationId?: string;
  Street?: string;
  ZipCode?: string;
  City?: string;
  Country?: string;
};

export type AllInOneProfileData = {
  Status?: {
    AddressCompleted: boolean;
    EmailVerified: boolean;
    AccountVerfified: boolean;
  };
  Companies?: UserCompanyData[];
  Language?: string;
  Permissions?: FakePermission[];
  Phone?: string | null;
  MobilePhone?: string;
  CreatedDateTime: string;
  Email: string;
  FirstName: string;
  LastName: string;
};

export type UpdateProfileResponseData = AllInOneProfileData & {
  required?: string[];
  additionalProperties?: boolean;
};

export interface SerialNotFoundError {
  message: 'errors.serial.not_found';
  details: { serial: string; language: string };
}

export interface SerialMachineResponse {
  result: boolean;
  data: SerialMachineData;
}

export type GSCSerialResponse = {
  isGsc: boolean;
  serial: string;
};

// https://portal.tst.api.grimme.com/docs/services/ProductRead/operations/GRIMMEConnectReadAPIMachinesGetProductBuilderDetailsStringStringStringNullab?
export type MachineConfiguration = {
  ProductBuilderGroup: {
    Id: string;
    Description: string;
  };
  ProductBuilderVariable: {
    Id: string;
    Description: string;
  };
  ProductBuilderVariableValue: {
    Id: string;
    Description: string;
  };
};

// https://portal.tst.api.grimme.com/docs/services/ProductDocumentRead/operations/GRIMMEConnectReadAPIMachineSpecificDocumentsGetMachineSpecificDocumentsStrin
interface ProductDocumentRead {
  DocTypeShort?: string;
  DocumentId: string; // required to download document
  FileExtension?: string;
  FileName?: string;
  HasDependentDocument?: true;
  EncodedDocumentHash: string; // required to download document
  DDF2?: string;
  DDF8?: string;
  DDF12: string; // document name
  DDF15?: string;
  DDF29?: string;
  DDF32?: string;
  DDF41?: string;
  DDF43?: string;
  DDF85?: string;
  DDF60?: string[];
  DDF61?: string[];
  DDF62?: string[];
  DDF63?: string[];
  DDF64?: string[];
  DDF65?: string[];
  DDF66?: string[];
  DDF67?: string[];
  DDF68?: string[];
  DDF69?: string[];
}

export type ProductDocumentReadResponse = ProductDocumentRead[];

interface MachineDocument {
  id: string;
  name: string;
  hash: string;
}

export type MachineDocumentListResponse = MachineDocument[];

// https://portal.tst.api.grimme.com/docs/services/ProfileWrite/operations/ProfileAPIUsersCreateOrUpdateMachineMappingStringMachineMappingModel?
export type UpsertMachineRequestData = MachineMapping;
export type EditMachineNameRequestData = Pick<
  MachineMapping,
  'MachineSerialNumber' | 'Name'
>;
export type UpsertMachineResponseData = MachineMapping[];

export type AddMachineError = {
  message: string;
};

export interface Dealer {
  Id: string;
  Name?: string;
  City?: string;
  EMail?: string;
  Telefax?: string;
  Phone?: string;
  Website?: string;
  Street?: string;
  ZipCode?: string;
  CountryId?: string;
  Longitude?: number;
  Latitude?: number;
  ShopParticipant?: boolean;
}

export type DealersResponse = Dealer[];

export interface LinkCardElement {
  disabled?: boolean;
  icon: IconProp;
  image?: string;
  index: number;
  target?: string;
  title: string;
  to?: string;
  trackEvent: string;
  visible?: boolean;
}

export interface SortCropsBody {
  sortedCropVarietyIds: number[];
}
